.footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #242424;
    padding: 2rem 0 2rem 0;
    color: #fff;
}

.footer-sections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}
.footer-top-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 1em;
}

.footer-subscription, .footer-links {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #fff;
    padding: 0 3em;
}

.footer-subscription-heading {
    font-size: 1.5em;
    color: #fff;
}

.footer-subscription > p {
    font-family: 'Alegreya Sans', sans-serif;
    font-family: 'Caveat', cursive;
    font-family: 'Space Grotesk', sans-serif;
}


.footer-subscription-text {
    margin-bottom: 1em;
    font-size: 1em;
}

.footer-input {
    padding: .5em 2em;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: 1px solid #6b8e23;
    font-size: 1em;
    margin-bottom: 1em;
}

.footer-links {
    display: flex;
    flex-direction: column;
    color: #fff;
    align-items: center;
    text-align: right;
}

.footer-links a {
    color: #fff;
    text-decoration: none;
    font-family: 'Alegreya Sans', sans-serif;
    font-family: 'Caveat', cursive;
    font-family: 'Space Grotesk', sans-serif;
}

.footer-links a:hover {
    color: #a3b899;
}

.footer-logo {
    display: flex;
    justify-content: center;
    background-color: #a3b899;
    width: 150px;
}

.website-rights-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.website-rights {
    color: #a3b899;
    font-size: 1em;
    text-align: center;
}

.social-icons {
    display: flex;
    justify-content: space-around;
    font-size: 2em;
}

.social-icon-link {
    color: #a3b899;
}

.social-media{
    padding: 0 2em;
}
