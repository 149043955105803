.navbar {
  background: #ff0000;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  white-space: nowrap;
  height: 80px;
  max-width: 100%;
  /*border: 2px solid red;*/
}

.logo {
  width: 100px;
  height: 100px;
  padding: 10px;
}

.navbar-logo {
  color: #242424;
  margin-left: 0px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  /*border: 2px solid red;*/
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: center;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #242424;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: 'Alegreya Sans', sans-serif;
  font-family: 'Caveat', cursive;
  font-family: 'Space Grotesk', sans-serif;
  
}

.nav-links:hover {
  border-bottom: 4px solid #242424;
  transition: all 0.2s ease-out;
  background-color: #a3b899;
  color: #fff;
}

.nav-last {
  position: absolute;
  right: 1px;
  padding: 15px;
  margin: 13px 82px;
}



.nav-link-last {
  text-decoration: none;
  border: 2px solid #a3b899;
  color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  padding: 15px;
  border-radius: 30px;
  font-family: 'Alegreya Sans', sans-serif;
  font-family: 'Caveat', cursive;
  font-family: 'Space Grotesk', sans-serif;
}

.nav-link-last:hover {
  background-color: #a3b899;
}
.nav-link-last:hover {
  color: #fff;
}

.fa-bars {
  color: #242424;
}

.nav-links-mobile {
  display: block;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-last {
    left: 50%;
    bottom: 60px;
    margin: 25px -69px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #a3b899;
    color: #242424;
    border-radius: 100px 0 0 0;
  }

  .navbar-logo {
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #242424;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #242424;
    padding: 14px 20px;
    border: 1px solid #242424;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #242424;
    color: #fff;
    transition: 250ms;
  }
}