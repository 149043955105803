video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

.hero-container {
    height: 50em;
    width: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 18px 6px -6px white inset,
    0px -18px 6px -6px white inset;
}

.hero-container > h1 {
    color: #fff;
    font-size: 90px;
    margin-top: 0px;
    font-family: 'Alegreya Sans', sans-serif;
    font-family: 'Caveat', cursive;
    font-family: 'Space Grotesk', sans-serif;
}

.hero-container > p {
    color: #fff;
    font-size: 32px;
    margin-top: 8px;
    font-family: 'Alegreya Sans', sans-serif;
    font-family: 'Caveat', cursive;
    font-family: 'Space Grotesk', sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

.mask-text {
    text-shadow: 10px 10px 10px rgba(0, 0.5, 0, 0.9);
}
.mask-text-2 {
    color: #a3b899;
    /*text-shadow: 5px 5px 5px rgba(163, 184, 153, 1);*/
    text-shadow: 3px 3px 3px rgba(31, 34, 31, 0.9);
}

@media screen and (max-width: 1200px) {

    .hero-container {
        height: 23em;
    }
    
}
@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 70px;
        margin-top: 0px;
    }

    .hero-container {
        height: 25em;
        width: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 18px 6px -6px white inset,
        0px -18px 6px -6px white inset;
    }
    
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        
        font-size: 50px;
        margin-top: 0px;
    }


    .hero-container {
        height: 30em;
        width: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 18px 6px -6px white inset,
        0px -18px 6px -6px white inset;
    }
}

@media screen and (max-width: 425px) {
    .hero-container > h1 {
        
        font-size: 30px;
        margin-top: 0px;
    }

    .hero-container {
        height: 30em;
        width: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 18px 6px -6px white inset,
        0px -18px 6px -6px white inset;
    }

    .hero-container > p {
        color: #fff;
        font-size: 25px;
        margin-top: 8px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }

    .btn-mobile {
        display:block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}