h1 {
    font-size: 3em;
}

p {
    font-size: 1.2em;
    width: 100%;
}

.timeline-container {
    display: flex;
    justify-content: center;
}