* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Alegreya Sans', sans-serif;
    font-family: 'Caveat', cursive;
    font-family: 'Space Grotesk', sans-serif;
  }

  .contact,
  .home,
  .values,
  .my-story,
  .menu,
  .reviews,
  .services {
    display: flex;
    height: auto;
    flex-direction: column;
    width: 100%;
  }

  /****************** MY STORY *********************/
  
  .my-story-title {
    font-size: 3em;
    text-align: left;
    padding-left: 1.7em;
    width: 100%;
  }

  .my-story-paragraph-container {
    color: #242424;
    text-align: left;
    width: 92%;
    padding: 2em 0 0 4.3em;
  }

  @media screen and (max-width:600px) {
    .my-story-paragraph-container{
    display: flex;
    flex-direction: column;
    width: 86%;
    }

    .image-border {
      display: flex;
      justify-content: center;
      padding: 2em;
    }

    .my-story-title {
      text-align: center;
      padding: 0;
    }
  }


  /****************** VALUES AND SERVICES*********************/

  .value-title {
    background-image: url('/');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #242424;
    font-size: 5em;
  }

  .value-pillars {
    display: flex;
    flex-direction: column;
    width: auto;
    flex-wrap: wrap;
    padding: 2em;
    align-items: center;
    width: 100%;
  }

  .value-card {
    display: flex;
    align-items: center;
    padding: 2em;
    width: 100%;
  }

  .left-side-card-values {
    display: flex;
    width: 40em;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    padding-right: 100px;
    padding-left: 90px;
    width: 100%;
  }

  .right-side-card-values {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .left-side-card-services {
    display: flex;
    width: 40em;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    padding-right: 100px;
    padding-left: 90px;
    width: 100%;
  }

  .right-side-card-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .value-card {
    width: 100%;
  }

  .value-card p {
    text-align: center;
    width: 100%;
  }

  .value-icons {
    height: 7em;
    width: 7em;
  }


  .value-pics {
    height: 25em;
    width: 33em;
  }


  .paragraph-container {
    margin: 2em 6em;
    color: #242424;
    text-align: justify;
    width: 100%;
  }

  .tgk-headshot {
    height: 20em;
    width: 15em;
    float: left;
    margin: 0 1em 1em 1em;
  }

  .tgk-signature {
    height: 5em;
    width: 10em;
    float: left;
  }

  .menu {
    background-image: url('/');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #242424;
    font-size: 5em;
  }

  .reviews {
    background-image: url('/');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #242424;
    font-size: 5em;
  }

  .services {
    background-image: url('/');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #242424;
    font-size: 5em;
  }

  .values, .services {
    padding-right: 17px;
  }

  @media screen and (max-width: 2560px) {

    .value-title {
      padding: 0.3em 0 0 0;
    }

    .value-card {
      width: 65%;
      padding-bottom: 3em;
    }
    .value-pics {
      height: 20em;
      width: 28em;
    }

    .value-titles {
      font-size: 1.6em;
    }
    .value-card p {
      text-align: center;
      font-size: 1.2em;
      width: 100%;
      padding: 0 2em;
      color: #6c8063;
      /*text-shadow: 0.5px 0.5px 0.5px rgba(31, 34, 31, 0.9);*/
    }
  
    .value-icons {
      height: 7.5em;
      width: 7.5em;
    }

    .left-side-card-values, .left-side-card-services {
      display: flex;
      width: 40em;
      flex-direction: column;
      align-items: center;
      font-size: 20px;
      padding-right: 1em;
      padding-left: 1em;
      width: 100%;
    }


  } 
    @media screen and (max-width: 1440px) {

      .value-pics {
        height: 19em;
        width: 26em;
      }
  
      .value-card {
        width: 90%;
      }

      .value-card p {
        text-align: center;
        font-size: 1em;
        width: 100%;
      }
    
      .value-icons {
        height: 4em;
        width: 4em;
      }
  
      .left-side-card-values, .left-side-card-services, .left-side-card-services {
        display: flex;
        width: 40em;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        padding-right: 1em;
        padding-left: 1em;
        width: 100%;
      }
  
      } 

      @media screen and (max-width: 960px) {

        .value-pics {
          height: 19em;
          width: 26em;
        }
    
        .value-card {
          width: 100%;
        }
      }
  @media screen and (max-width: 768px) {

    .value-pics {
      height: 25em;
      width: 34em;
    }

    .value-card p {
      text-align: center;
      font-size: 1em;
      width: 100%;
      padding-bottom: 2em;
    }
  
    .value-icons {
      height: 3em;
      width: 3em;
    }

    .left-side-card-values, .left-side-card-services {
      display: flex;
      width: 40em;
      flex-direction: column;
      align-items: center;
      font-size: 20px;
      padding-right: 1em;
      padding-left: 1em;
      width: 100%;
      text-align: center;
    }

    .value-card {
      flex-direction: column;
    }

    .value-love, .value-precision, .service-vacation, .service-lessons{
      flex-direction: column-reverse;
    }
    } 
  
  @media screen and (max-width: 600px) {
    .value-pics {
      height: 18em;
      width: 25em;
    }

    .value-card p {
      text-align: center;
      font-size: 1em;
      width: 100%;
      padding-bottom: 2em;
    }
  
    .value-icons {
      height: 3em;
      width: 3em;
    }

    .left-side-card-values, .right-side-card-services {
      display: flex;
      width: 40em;
      flex-direction: column;
      align-items: center;
      font-size: 20px;
      padding-right: 1em;
      padding-left: 1em;
      width: 100%;
    }

    .value-card {
      flex-direction: column;
    }

    .value-love, .value-precision, .service-vacation, .service-lessons{
      flex-direction: column-reverse;
    } 
  }
    @media screen and (max-width: 425px) {
      .value-pics {
        width: 100%;
        height: 100%;
      }
  
      .value-card p {
        text-align: center;
        font-size: 0.7em;
        width: 100%;
        padding: 2em 0;
      }
    
      .value-icons {
        height: 3em;
        width: 3em;
      }
  
      .left-side-card-values, .right-side-card-services {
        display: flex;
        width: 40em;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        padding-right: 1em;
        padding-left: 1em;
        width: 100%;
      }
  
      .value-card {
        flex-direction: column;
        width:100%
      }
  
      .value-love, .value-precision, .service-vacation, .service-lessons{
        flex-direction: column-reverse;
      } 
    }

    /**************** MENU ****************/

    .instagram-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 4em 4em;
    }

    .post-1 {
      clip: rect(0px,50px,100px,0px);
    }

    /**************** CONTACT ****************/

    .contact {
      background-image: url('/');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      color: #242424;
      font-size: 5em;
    }
  
    .columns-3 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
    }
  
    .contact-form {
      display: flex;
      flex-direction: column;
      padding: 2em 0;
    }
  