:root {
    --primary: #a3b899;

}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: #a3b899;
    color: #fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: white;
    color: #242424;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn-medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px
}

.btn--primary:hover  {
    background: white;
    color: black;
    transition: all 0.3s ease-out;
}

.btn--outline:hover {
    background: #a3b899;
    color: black;
    transition: all 0.3s ease-out;
}